import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

export default function TableExport({ exports }) {
  function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  }

  const copied = () => {
    toast.success("Text Copied", {
      position: "bottom-center",
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <tr key={exports.id}>
      {/* <th scope="row">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            // name="chk_child"
            defaultValue={apple.id}
            onChange={handleCheckboxChange}
            // checked={checkedItems.includes(apple.id)}
          />
        </div>
      </th> */}
      <td className="id" style={{ display: "none" }}>
        {/* <Link to="#" className="fw-medium link-primary"> */}
        {exports.id}
        {/* </Link> */}
      </td>
      <td className="customer_name">{exports.id}</td>
      <td className="email">{exports.filename}</td>

      <td className="date">{timeSince(new Date(exports.created_at))}</td>

      <td>
        <div className="d-flex gap-2">
          <div className="link">
            <a
              href={`https://api.hkj-haitham-tec.com/public/storage/${exports.filename}`}
              className="btn  btn-info btn-md edit-item-btn"
              target="_blank"
            >
              <i className="ri-link align-bottom"></i>
            </a>
          </div>

          <div className="copy">
            <div
              className="btn   btn-md edit-item-btn"
              style={{ backgroundColor: "#0b9b42", color: "#fff" }}
            >
              <CopyToClipboard
                text={`https://api.hkj-haitham-tec.com/public/storage/${exports.filename}`}
                //   className="btn  btn-success edit-item-btn"
                onCopy={copied}
              >
                <i className="ri-clipboard-line align-bottom "></i>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
}
