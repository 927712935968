import React, { useState } from "react";
import { Col, Modal, Row, Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { addApple, updateApple } from "../../../store/appleid/actions";
import { useDispatch } from "react-redux";

const schema = yup.object().shape({
  email: yup.string().max(100).required(),
  password: yup.string().max(100).required(),

  question1: yup.string().max(100).required(),
  question2: yup.string().max(100).required(),
  question3: yup.string().max(100).required(),

  answer1: yup.string().max(100).required(),
  answer2: yup.string().max(100).required(),
  answer3: yup.string().max(100).required(),

  name: yup.string().max(100).required(),

  friendname: yup.string().max(100).required(),
  street: yup.string().max(100).required(),
  city: yup.string().max(100).required(),
  region: yup.string().max(100).required(),

  birthday: yup.date().required(),
  //   note: yup.string(),
  //   image: yup.mixed().required(),
  // shownote: yup.bol().required(),
});

export default function ShowNew({
  modal_center,
  tog_center,
  setmodal_center,
  data,
}) {
  const dispatch = useDispatch();

  const [files, setFiles] = useState(null);
  const [base64Image, setBase64Image] = useState("");

  const date = new Date(data.birthday);

  const formattedDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: data.email,
      password: data.password,

      question1: data.question1,
      question2: data.question2,
      question3: data.question3,

      answer1: data.answer1,
      answer2: data.answer2,
      answer3: data.answer3,

      name: data.name,

      friendname: data.friendname,
      street: data.street,
      city: data.city,
      region: data.region,

      birthday: formattedDate,
      note: data.note,
      shownote: data.shownote,
      state: data.state,
    },
  });

  const onSubmit = (formData) => {
    if (Object.keys(data).length !== 0) {
      // let data = data.append("image", files);
      dispatch(updateApple({ ...formData, id: data.id, image: base64Image }));
    } else {
      // let data = data.append("image", files);
      dispatch(addApple({ ...formData, image: base64Image }));
    }
    if (!Object.keys(errors).length) {
      //   reset();
      setmodal_center(false);
    }
  };

  const imageUploadChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setBase64Image(reader.result);
      };

      reader.readAsDataURL(file);
      setFiles(file);
    }

    //   const src = URL.createObjectURL(e.target.files[0]);
  };

  return (
    <Modal
      isOpen={modal_center}
      toggle={() => {
        tog_center();
      }}
      centered
    >
      <div className="modal-header">
        <h5
          className="modal-title mt-0"
          style={{ display: "flex", alignItems: "center" }}
        >
          <i class="ri-apple-fill" style={{ paddingRight: 5 }}></i>{" "}
          {Object.keys(data).length !== 0 ? "Update" : "Add"} Apple ID
        </h5>
        <button
          type="button"
          onClick={() => {
            setmodal_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-2">
            <Col md="12">
              <label for="file-input" className="filelabel">
                <input
                  id="file-input"
                  type="file"
                  className="fileinput"
                  onChange={imageUploadChange}
                />
                <img
                  className="logoimg"
                  id="img-preview"
                  onload="showToast()"
                  src={
                    files
                      ? URL.createObjectURL(files)
                      : data.image
                      ? `https://api.hkj-haitham-tec.com/public/storage/${data.image}`
                      : `https://api.hkj-haitham-tec.com/public/storage/logo.png`
                  }
                />
                <p>Click the Image to Upload</p>
              </label>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col md="6">
              {/* <Input bsSize="lg" type="email" placeholder="Apple ID" /> */}
              <div className="field">
                <input
                  {...register("email")}
                  className={`form-control ${
                    errors.email ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="email"
                />
                <span class="label">Enter email</span>
              </div>
            </Col>
            <Col md="6">
              <div className="field">
                <input
                  {...register("password")}
                  className={`form-control ${
                    errors.password ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="text"
                />
                <span class="label">Password</span>
              </div>
            </Col>
          </Row>

          <hr />

          <Row className="mb-3">
            <Col md="6">
              <div className="field">
                <select
                  className={`form-control ${
                    errors.question1 ? "is-invalid" : null
                  }`}
                  id="select1"
                  defaultValue="What is the first name of your best friend in high school?"
                  {...register("question1")}
                >
                  <option value="What is the first name of your best friend in high school?">
                    What is the first name of your best friend in high school?
                  </option>
                  <option value="What was the name of your first pet?">
                    What was the name of your first pet?
                  </option>
                  <option value="What was the first thing you learned to cook?">
                    What was the first thing you learned to cook?
                  </option>
                  <option value="What was the first film you saw in the theater?">
                    What was the first film you saw in the theater?
                  </option>
                  <option value="Where did you go the first time you flew on a plane?">
                    Where did you go the first time you flew on a plane?
                  </option>
                  <option value="What is the last name of your favorite elementary school teacher?">
                    What is the last name of your favorite elementary school
                    teacher?
                  </option>
                </select>
              </div>
            </Col>
            <Col md="6">
              <div className="field">
                <input
                  className={`form-control ${
                    errors.answer1 ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="text"
                  {...register("answer1")}
                />
                <span class="label">Answer 1</span>
              </div>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md="6">
              <div className="field">
                <select
                  className={`form-control ${
                    errors.question2 ? "is-invalid" : null
                  }`}
                  id="select1"
                  defaultValue="What is your dream job?"
                  {...register("question2")}
                >
                  <option value="What is your dream job?">
                    What is your dream job?
                  </option>
                  <option value="What is your favorite children’s book?">
                    What is your favorite children’s book?
                  </option>
                  <option value="What was the model of your first car?">
                    What was the model of your first car?
                  </option>
                  <option value="What was your childhood nickname?">
                    What was your childhood nickname?
                  </option>
                  <option value="Who was your favorite film star or character in school?">
                    Who was your favorite film star or character in school?
                  </option>
                  <option value="Who was your favorite singer or band in high school?">
                    Who was your favorite singer or band in high school?
                  </option>
                </select>
              </div>
            </Col>
            <Col md="6">
              <div className="field">
                <input
                  className={`form-control ${
                    errors.answer2 ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="text"
                  {...register("answer2")}
                />
                <span class="label">Answer 2</span>
              </div>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md="6">
              <div className="field">
                <select
                  className={`form-control ${
                    errors.question3 ? "is-invalid" : null
                  }`}
                  id="select1"
                  // value="1"
                  defaultValue="In what city did your parents meet?"
                  {...register("question3")}
                >
                  <option value="In what city did your parents meet?">
                    In what city did your parents meet?
                  </option>
                  <option value="What was the first name of your first boss?">
                    What was the first name of your first boss?
                  </option>
                  <option value="What is the name of the street where you grew up?">
                    What is the name of the street where you grew up?
                  </option>
                  <option value="What is the name of the first beach you visited?">
                    What is the name of the first beach you visited?
                  </option>
                  <option value="What was the first album that you purchased?">
                    What was the first album that you purchased?
                  </option>
                  <option value="What is the name of your favorite sports team?">
                    What is the name of your favorite sports team?
                  </option>
                </select>
              </div>
            </Col>
            <Col md="6">
              <div className="field">
                <input
                  className={`form-control ${
                    errors.answer3 ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="text"
                  {...register("answer3")}
                />
                <span class="label">Answer 3</span>
              </div>
            </Col>
          </Row>

          <hr />

          <Row className="mb-3">
            <Col md="6" className="mb-3">
              <div className="field">
                <input
                  className={`form-control ${
                    errors.name ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="text"
                  {...register("name")}
                />
                <span class="label">Name in apple account</span>
              </div>
            </Col>
            <Col md="6" className="mb-3">
              <div className="field">
                <input
                  className={`form-control ${
                    errors.birthday ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="date"
                  //   value={formatDate(Date.parse(data.birthday))}
                  {...register("birthday")}
                />
                <span class="label">Birthday</span>
              </div>
            </Col>

            <Col md="6" className="mb-3">
              <div className="field">
                <input
                  className={`form-control ${
                    errors.friendname ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="text"
                  {...register("friendname")}
                />
                <span class="label"> Name (first & last)?</span>
              </div>
            </Col>

            <Col md="6" className="mb-3">
              <div className="field">
                <input
                  className={`form-control ${
                    errors.street ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="text"
                  {...register("street")}
                />
                <span class="label">Street</span>
              </div>
            </Col>
            <Col md="6" className="mb-3">
              <div className="field">
                <input
                  className={`form-control ${
                    errors.city ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="text"
                  {...register("city")}
                />
                <span class="label">City</span>
              </div>
            </Col>
            <Col md="6" className="mb-3">
              <div className="field">
                <input
                  className={`form-control ${
                    errors.region ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="text"
                  {...register("region")}
                />
                <span class="label">Region</span>
              </div>
            </Col>

            <hr />

            <Col md="12">
              <div className="field  mb-3">
                <textarea
                  className={`form-control ${
                    errors.note ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="date"
                  {...register("note")}
                ></textarea>
                <span class="label">Note</span>
              </div>
            </Col>
            <Col md="6">
              <div className="form-check form-switch form-switch-lg mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg"
                  {...register("shownote")}
                />
                <label
                  className="form-check-label"
                  htmlFor="customSwitchsizelg"
                >
                  Show Note
                </label>
              </div>
            </Col>
            <Col md="6">
              <div className="form-check form-switch form-switch-lg mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg"
                  {...register("state")}
                />
                <label
                  className="form-check-label"
                  htmlFor="customSwitchsizelg"
                >
                  Sold
                </label>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}
