import React, { useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { format, parse } from "date-fns";
import AppleLogo from "./assets/Apple.png";
import megaLogo from "./assets/logo.png";
import svgw from "./assets/ww.svg";
import { triggerBase64Download } from "react-base64-downloader";
import withRouter from "../../components/Common/withRouter";

import Api from "../../api/Api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { useScreenshot } from "use-react-screenshot";

const Public = (props) => {
  document.title = "Apple Ids";

  const [isLoading, setLoading] = React.useState(true);
  const [isError, setError] = React.useState(true);

  const [data, setData] = React.useState([]);
  const [modal, setModal] = React.useState(true);

  const toggle = () => setModal(!modal);

  const ref = useRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = () => {
    takeScreenshot(ref.current);
  };

  React.useEffect(() => {
    if (image) {
      triggerBase64Download(image, "MegaAppleId");
    }
  }, [image]);

  React.useEffect(() => {
    setLoading(true);
    Api.get(window.location.pathname)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("No Apple ID please check the url", {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, []);

  const copied = () => {
    toast.success("Text Copied", {
      position: "bottom-center",
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  };

  const formatDateForSafari = (dateString) => {
    try {
      // If dateString is already in ISO format or can be parsed as a valid date
      const date = new Date(dateString);

      if (!isNaN(date.getTime())) {
        // If valid, return only the YYYY-MM-DD part
        return date.toISOString().split("T")[0];
      }

      // If the date isn't valid, attempt manual parsing as a fallback
      const parts = dateString.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1].padStart(2, "0");
        const day = parts[2].padStart(2, "0");
        return `${year}-${month}-${day}`;
      }

      return "N/A"; // Fallback if the date isn't parseable
    } catch (error) {
      return "N/A"; // Handle unexpected errors
    }
  };

  return (
    <React.Fragment>
      <div className="page-content" ref={ref}>
        {isLoading ? (
          <div style={{ textAlign: "center", marginTop: 200 }}>
            <Spinner type="grow">Loading...</Spinner>
          </div>
        ) : (
          <Container fluid>
            {/* <Breadcrumb title="Upzet" breadcrumbItem="Profile" /> */}
            <img
              height={200}
              src={`${
                data.image === "logo.png"
                  ? megaLogo
                  : `https://api.hkj-haitham-tec.com/public/storage/${data.image}`
              }`}
              className="mt-4 mb-4 ml-auto mr-auto d-flex"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
                width: 400,
                height: "auto",
              }}
            />

            <p className="text-center mt-4 mb-4" style={{ fontSize: 16 }}>
              Account Number:{" "}
              <span
                style={{
                  padding: "5px 10px",
                  background: "#000972",
                  color: "#fff",
                  borderRadius: 5,
                }}
              >
                {data?.id}
              </span>
            </p>

            <Row className="justify-content-center">
              <Col md="6">
                <Card style={{ padding: 15 }}>
                  <CardBody>
                    <Row className="mb-3">
                      <div className="col-md-5">
                        <img
                          height={200}
                          src={AppleLogo}
                          className="mt-4 mb-4 ml-auto mr-auto d-flex hide-on-mobile"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "50%",
                            height: "auto",
                          }}
                        />
                      </div>
                      <div className="col-md-7">
                        <h5>Apple ID:</h5>
                        <div className="public-apple mb-3">
                          <div className="public-apple-button">
                            <div className="d-block">
                              <h4>Email:</h4>
                              <span> {data.email}</span>
                            </div>
                            <CopyToClipboard text={data.email} onCopy={copied}>
                              <i className="ri-file-copy-line align-bottom "></i>
                            </CopyToClipboard>
                          </div>
                          <div className="public-apple-button">
                            <div className="d-block">
                              <h4>Password:</h4>
                              <span> {data.password}</span>
                            </div>
                            <CopyToClipboard
                              text={data.password}
                              onCopy={copied}
                            >
                              <i className="ri-file-copy-line align-bottom "></i>
                            </CopyToClipboard>
                          </div>
                          {/* {data.birthday ? ( */}
                          <div className="public-apple-button">
                            <div className="d-block">
                              <h4>Birthday:</h4>
                              <span>
                                {" "}
                                {/* {format(new Date(data.birthday), "yyyy-MM-dd")} */}
                                {formatDateForSafari(data.birthday)}
                              </span>
                            </div>
                            <CopyToClipboard
                              text={formatDateForSafari(data.birthday)}
                              onCopy={copied}
                            >
                              <i className="ri-file-copy-line align-bottom "></i>
                            </CopyToClipboard>
                          </div>
                          {/* ) : null} */}
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-12 mb-3">
                        <h5>Security Question:</h5>
                        <div className="public-apple2 mb-3">
                          <div className="public-apple-button2">
                            <div className="d-block">
                              <h4>{data.question1}</h4>
                              <span>{data.answer1}</span>
                            </div>
                            <CopyToClipboard
                              text={data.answer1}
                              onCopy={copied}
                            >
                              <i className="ri-file-copy-line align-bottom "></i>
                            </CopyToClipboard>
                          </div>
                          <div className="public-apple-button2">
                            <div className="d-block">
                              <h4>{data.question2}</h4>
                              <span>{data.answer2}</span>
                            </div>
                            <CopyToClipboard
                              text={data.answer2}
                              onCopy={copied}
                            >
                              <i className="ri-file-copy-line align-bottom "></i>
                            </CopyToClipboard>
                          </div>
                          <div className="public-apple-button2">
                            <div className="d-block">
                              <h4>{data.question3}</h4>
                              <span>{data.answer3}</span>
                            </div>
                            <CopyToClipboard
                              text={data.answer3}
                              onCopy={copied}
                            >
                              <i className="ri-file-copy-line align-bottom "></i>
                            </CopyToClipboard>
                          </div>
                        </div>
                        {/* <div className="public-input mb-3">
                        <div className="public-input-info">
                          <h4>{data.question2}</h4>
                          <span>{data.answer2}</span>
                        </div>
                        <div className="public-input-button">
                          <CopyToClipboard text={data.answer2}>
                            <i className="ri-file-copy-line align-bottom "></i>
                          </CopyToClipboard>
                        </div>
                      </div>
                      <div className="public-input mb-3">
                        <div className="public-input-info">
                          <h4>{data.question3}</h4>
                          <span>{data.answer3}</span>
                        </div>
                        <div className="public-input-button">
                          <CopyToClipboard text={data.answer3}>
                            <i className="ri-file-copy-line align-bottom "></i>
                          </CopyToClipboard>
                        </div>
                      </div> */}
                      </div>
                    </Row>
                    <Row>
                      <div className="col-12 mb-3">
                        <h5>
                          Information Payment & Shipping & Billing Address:
                        </h5>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="public-apple mb-3">
                                <div className="public-apple-button">
                                  <div className="d-block">
                                    <h4>Name in apple account:</h4>
                                    <span> {data.name}</span>
                                  </div>
                                  <CopyToClipboard
                                    text={data.name}
                                    onCopy={copied}
                                  >
                                    <i className="ri-file-copy-line align-bottom "></i>
                                  </CopyToClipboard>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="public-apple mb-3">
                                <div className="public-apple-button">
                                  <div className="d-block">
                                    <h4> Name (first & last)?:</h4>
                                    <span> {data.friendname}</span>
                                  </div>
                                  <CopyToClipboard
                                    text={data.friendname}
                                    onCopy={copied}
                                  >
                                    <i className="ri-file-copy-line align-bottom "></i>
                                  </CopyToClipboard>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="public-apple mb-3">
                                <div className="public-apple-button">
                                  <div className="d-block">
                                    <h4>Street:</h4>
                                    <span> {data.street}</span>
                                  </div>
                                  <CopyToClipboard
                                    text={data.street}
                                    onCopy={copied}
                                  >
                                    <i className="ri-file-copy-line align-bottom "></i>
                                  </CopyToClipboard>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="public-apple mb-3">
                                <div className="public-apple-button">
                                  <div className="d-block">
                                    <h4>City:</h4>
                                    <span> {data.city}</span>
                                  </div>
                                  <CopyToClipboard
                                    text={data.city}
                                    onCopy={copied}
                                  >
                                    <i className="ri-file-copy-line align-bottom "></i>
                                  </CopyToClipboard>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="public-apple mb-3">
                                <div className="public-apple-button">
                                  <div className="d-block">
                                    <h4>Region:</h4>
                                    <span> {data.region}</span>
                                  </div>
                                  <CopyToClipboard
                                    text={data.region}
                                    onCopy={copied}
                                  >
                                    <i className="ri-file-copy-line align-bottom "></i>
                                  </CopyToClipboard>
                                </div>
                              </div>
                            </div>

                            {data.purchasedate ? (
                              <div className="col-md-12">
                                <div className="public-apple mb-3">
                                  <div className="public-apple-button">
                                    <div className="d-block">
                                      <h4>Purchase Date:</h4>
                                      <span>
                                        {" "}
                                        {format(
                                          new Date(data.purchasedate),
                                          "yyyy-MM-dd"
                                        )}
                                      </span>
                                    </div>
                                    <CopyToClipboard
                                      text={data.purchasedate}
                                      onCopy={copied}
                                    >
                                      <i className="ri-file-copy-line align-bottom "></i>
                                    </CopyToClipboard>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {data.shownote ? (
                        <div className="col-12">
                          <h5>Note:</h5>
                          <div className="public-apple mb-4">
                            <div className="public-apple-button">
                              <CopyToClipboard text={data.note} onCopy={copied}>
                                <>
                                  {data.note}{" "}
                                  <i className="ri-file-copy-line align-bottom "></i>
                                </>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <Button
                        color="success"
                        className="add-btn"
                        onClick={getImage}
                        id="create-btn"
                      >
                        <i className="ri-screenshot-2-line align-bottom me-1"></i>{" "}
                        Save As Image
                      </Button>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal isOpen={modal} toggle={toggle} size="lg">
              <ModalBody>
                <p
                  className="text-right"
                  style={{
                    fontSize: 18,
                    textAlign: "right",
                    direction: "rtl",
                  }}
                >
                  <img
                    src={svgw}
                    width="20%"
                    style={{
                      display: "block",
                      margin: "auto",
                      marginBottom: 20,
                    }}
                  />
                  يستخدم الحساب حاليا كـ ابل ايدي فقط وعند استخدام الحساب في
                  الأيكلود يكون على مسؤوليتك الشخصية (وفي هذه الحالة يجب ربط
                  الحساب برقم هاتفك وإيقاف ميزة العثور على الأيفون) • الضمان
                  يشمل استبدال الحساب العاطل قبل استخدمه في الجهاز فقط ولمدة
                  ثلاثة أشهر من تاريخ البيع • بسبب تحديث سياسات شركة أبل
                  الأخيرة، المكتب غير مسؤول عن سوء الاستخدام وينتهي التزامه
                  بتسليم الكارت للمستخدم حيث تقوم الشركة بأغلاق الحسابات التي
                  يقوم بها المستخدم بإدخال معلومات خاطئة • عند شرائك الحساب
                  واتمام عملية تسجيل الدخول تكون مسؤولا عن جميع المعلومات
                  المسجلة للاستخدام والمكتب غير مسؤول عن فقدان الوصول للحساب بعد
                  تسجيل الدخول • عمليات الشراء داخل الحساب غير مكفولة وعلى
                  مسؤوليتك الشخصية • المكتب غير مسؤول بتقديم تعهد بضمان استمرار
                  الخدمات بلا انقطاع او خلل فني من الشركة الام او نتيجة لإيقاف
                  الخدمات بالبلد المستخدم
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  اغلاق
                </Button>
              </ModalFooter>
            </Modal>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Public);
